import React from 'react';
import { QR } from "../components/QR";
import {useSearchParams} from 'react-router-dom';
import { inchWithDpi } from './utils/measurements';
import Sheet from './utils/sheet';
import { ProductUnitProps } from '../screens/ProductUnit';
import { getTagRefs, getTheme } from './utils/tags';
import { TagColors } from '../generated/apiclient';


type TagParams = {
  shortId: string,
  url: string,
  width: number,
  height: number,
  colors: TagColors,
  outline?: boolean,
  message?: string,
}

export const RectangleTag = ({shortId, width, height, url, outline, message, colors}: TagParams) => {
  const dpi = 96;
  const inch = inchWithDpi(dpi);

  return (
    <div style={{
      width: width+"px",
      height: height+"px",
      display: 'flex', 
      position: 'relative',
      flexDirection: 'row',
      borderRadius: '8px', 
      alignItems: 'center', 
      border: outline ? '0.1px solid #000' : undefined
      //justifyContent: 'center'
      }}>

      {
        message ? 
          <div style={{position: 'absolute', bottom: '8px', left: '144px'}}>
            <span style={{
              fontFamily: "Cinzel", fontSize:"16px", fontWeight: "600",
              color: colors.secondary}}>
                {message}
            </span>
          </div>
        : null
      }

      {
        shortId == 'demo' ? 
          <div style={{
            position: 'absolute', rotate: '-30deg', top: '48px', right: '10px', 
            color: '#f00', border: '1px solid #f00', borderRadius: '8px',
            fontFamily: "Quicksand", fontSize:"8px", padding: '4px', fontWeight: '600',
            textAlign: 'center'
          }}>
            <div style={{fontSize:"15px"}}>Demo Tag</div>
            <div style={{fontSize:"9px"}}>Scan to learn how it works</div>
          </div>
        : null
      }

      <div style={{
        display: 'flex', flexDirection: 'column', textAlign:"center",
        margin: '12px'
      }}>
        <QR 
          qrStyle="square" style={{margin: '0 auto'}} url={url} 
          overlayText={shortId} sizePx={inch(3/4)} colors={colors} />
        <span style={{fontFamily: "Bungee", fontSize:"11px", color: colors.secondary}}>
          LoudTags.com
        </span>
        <span style={{
          fontFamily: "Handlee", marginTop: '-3px', fontWeight:"600", fontSize:"10px",
          color: colors.primary}}>
          Scan for Your Voice Message
        </span>
      </div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <span style={{
          fontFamily: "Handlee", fontWeight:"300", fontSize:"16px", 
          marginBottom: '30px',
          color: colors.primary}}>
          To:
        </span>
        <span style={{
          fontFamily: "Handlee", fontWeight:"300", fontSize:"16px",
          marginBottom: '30px',
          color: colors.primary}}>
          From:
        </span>
      </div>
    </div>
  );
};

function RectangleGiftStickers({unit, product}:ProductUnitProps) {
    const tags = unit.tags!;
    const nRows = 5;
    const nCols = 2;

    const dpi = 96;
    const inch = inchWithDpi(dpi);
    const baseUrl = window?.location?.origin ?? 'https://loudtags.com';

    const [queryParams] = useSearchParams();
    const bumpRightInch = parseFloat(queryParams.get('bumpRight') ?? '0');
    const bumpDownInch = parseFloat(queryParams.get('bumpDown') ?? '0');


    const items: Array<Array<React.ReactNode>> = [];
    for (let row = 0; row < nRows; row++) {
      items[row] = []; 
      for (let col = 0; col < nCols; col++) {
        const idx = row*nCols+col;
        const {shortId, url} = getTagRefs(baseUrl, tags[idx]);
        const {message, colors} = getTheme(product, unit, idx);

        items[row][col] = (shortId ? <RectangleTag 
          shortId={shortId} 
          width={inch(3.5)}
          height={inch(1.75)}
          outline={false}
          message={message}
          url={url}
          colors={colors} /> 
          : undefined);
      }
    }

    return (
      <Sheet 
        items={items}
        dpi={dpi}
        widthInch={8.5}
        heightInch={11}
        marginTopInch={3/8}
        marginBottomInch={3/8}
        marginLeftInch={11/32}
        marginRightInch={11/32}
        itemSpacingHorizontalInch={13/16}
        itemSpacingVerticalInch={3/8}
        itemWidthInch={3.5}
        itemHeightInch={1.75}
        bleedInch={0}
        bumpRightInch={bumpRightInch}
        bumpDownInch={bumpDownInch}
        title={``}
        outline={false}
      />
    );
  }
  
  export default RectangleGiftStickers;
import { Outlet } from "react-router-dom";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import Footer from "./components/Footer";

const theme = createTheme({
  palette: {
    mode: 'light',

    primary: {
      main: "#888", // ' //lightBlue[900]
      contrastText: '#fff'
    },
    text: {
      primary: "#fff",
      secondary:'#444'
    },


  },
  typography: {
    fontFamily: 'Trebuchet MS',
  },
});
function Website() {
  return (

    <ThemeProvider theme={theme}>
      <Box    
        sx={{
          background: "#F8F7F7",
        }}

      >
        <Outlet/>
        <Footer/>
      </Box>
    </ThemeProvider>


  );
}

export default Website;
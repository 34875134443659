import React from 'react';
import { Container, Typography } from '@mui/material';

interface ListSectionProps {
  id?: string,
  title?: string,
  items: { heading: string; text: React.ReactNode }[];
}

const ListSection: React.FC<ListSectionProps> = ({id, title, items }) => {
  return (
    <Container id={id} style={{
      marginTop: '20px',
      maxWidth: '900px'
    }}>
      {
        title ?
        <Typography variant='h2' style={{fontSize: '36px', marginBottom: '36px'}}>
          {title}
        </Typography>
        : null
      }
      {items.map((i, index) => (
        <div key={index}>
          <Typography variant="h5" style={{
            color: '#000',
            marginTop: '30px'
          }}>
            {i.heading}
          </Typography>
          <Typography style={{
            color: '#333',
            marginTop: '8px',
            fontSize: '16px',
          }}>{i.text}</Typography>
        </div>
      ))}
    </Container>
  );
};

export default ListSection;
import { useEffect, useRef } from 'react';
import './WaveProgress.css'

type Audio = {
    ctx: AudioContext,
    src: AudioNode,
}

type FrequencyBarsParams = {
    progress: number,
    animating: number,
    audio: Audio,
};

const BAR_COUNT = 30;
const MAX_BAR_HEIGHT = 140.0;
const FPS = 4;

export const FrequencyBars = ({progress, animating, audio}: FrequencyBarsParams) => {
    const defaultVals = new Uint8Array(BAR_COUNT).fill(3);
    const barRefs = useRef<Array<HTMLDivElement|null>>([]);

    const setBarHeights = (vals: Uint8Array) => {
        // we only use BAR_COUNT values from the spectrum readings
        // many values (especially the top end) are outside the vocal range
        const middleOut = (idx: number): number => {
            const half = BAR_COUNT / 2;
            const modifier = idx >= half
              ? (d: number) => 2*d
              : (d: number) => 2*d-1;
            const dist = Math.abs(idx - half);
            return modifier(dist);
        }
        for (let i = 0; i < BAR_COUNT; i++) {
            const idx = middleOut(i);
            const v = vals[idx] / 255.0;
            let height = Math.max(3, MAX_BAR_HEIGHT*v);
            if (barRefs.current[i]) {
                barRefs.current[i]!.style.height = `${height}px`;
            }
        }
    };

    useEffect(() => {
        let handle: NodeJS.Timer|null = null;
        if (audio && animating) {
            const analyser = new AnalyserNode(audio.ctx, {
                fftSize: 128, // results in 64 bins
                maxDecibels: -30,
                minDecibels: -90,
                smoothingTimeConstant: 0,
            });

            audio.src.connect(analyser);
            const freqData = new Uint8Array(analyser.frequencyBinCount);

            setBarHeights(freqData);
            let i = 0;
            handle = setInterval(() => {
                if (analyser) {
                    analyser.getByteFrequencyData(freqData);
                    setBarHeights(freqData);
                } else {
                    setBarHeights(defaultVals);
                }
            }, 1000 / FPS);
        } else {
            if (handle) {
                clearInterval(handle);
                handle = null;
            }
            setBarHeights(defaultVals);
        }
        return function cleanup() {
            if (handle) {
                clearInterval(handle);
                handle = null;
            }
        }
    }, [audio, animating]);

    let bars = [];
    for (let i = 0; i < BAR_COUNT; i++) {
        bars.push(
        <div 
            key={i} 
            ref={el => barRefs.current[i] = el} 
            className='bars'
            style={{
                height: `3px`,
                backgroundColor: '#FAE075',
            }}>
        </div>
        );
    }

    return (
        <div style={{height: '100%', width: '100%'}}>
            <div style={{
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '140px',  
                width: '100%'
            }}>{bars}</div>
        </div>
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Product,
  Unit,
} from '../models';
import {
    ProductFromJSON,
    ProductToJSON,
    UnitFromJSON,
    UnitToJSON,
} from '../models';

export interface GetProductRequest {
    productId: string;
}

export interface GetProductUnitRequest {
    productId: string;
    unitId: string;
}

export interface GetProductUnitPDFRequest {
    productId: string;
    unitId: string;
}

export interface GetProductUnitsRequest {
    productId: string;
}

export interface PostProductUnitRequest {
    productId: string;
    unit: Unit;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     * Get Product
     * Get Product
     */
    async getProductRaw(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/products/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Get Product
     * Get Product
     */
    async getProduct(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.getProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get product unit
     * Get Product Unit
     */
    async getProductUnitRaw(requestParameters: GetProductUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Unit>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductUnit.');
        }

        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId','Required parameter requestParameters.unitId was null or undefined when calling getProductUnit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products/{productId}/units/{unitId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))).replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnitFromJSON(jsonValue));
    }

    /**
     * Get product unit
     * Get Product Unit
     */
    async getProductUnit(requestParameters: GetProductUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Unit> {
        const response = await this.getProductUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get product unit as PDF
     * Get Product Unit PDF
     */
    async getProductUnitPDFRaw(requestParameters: GetProductUnitPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductUnitPDF.');
        }

        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId','Required parameter requestParameters.unitId was null or undefined when calling getProductUnitPDF.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products/{productId}/units/{unitId}.pdf`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))).replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get product unit as PDF
     * Get Product Unit PDF
     */
    async getProductUnitPDF(requestParameters: GetProductUnitPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getProductUnitPDFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Product Units
     * Get Product Unit List
     */
    async getProductUnitsRaw(requestParameters: GetProductUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Unit>>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductUnits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/products/{productId}/units`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnitFromJSON));
    }

    /**
     * Get Product Units
     * Get Product Unit List
     */
    async getProductUnits(requestParameters: GetProductUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Unit>> {
        const response = await this.getProductUnitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Products
     * Get Product List
     */
    async getProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Product>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
    }

    /**
     * Get Products
     * Get Product List
     */
    async getProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Product>> {
        const response = await this.getProductsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create new Unit
     * Create a new Unit for Product
     */
    async postProductUnitRaw(requestParameters: PostProductUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Unit>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling postProductUnit.');
        }

        if (requestParameters.unit === null || requestParameters.unit === undefined) {
            throw new runtime.RequiredError('unit','Required parameter requestParameters.unit was null or undefined when calling postProductUnit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/products/{productId}/units`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnitToJSON(requestParameters.unit),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnitFromJSON(jsonValue));
    }

    /**
     * Create new Unit
     * Create a new Unit for Product
     */
    async postProductUnit(requestParameters: PostProductUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Unit> {
        const response = await this.postProductUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import React from 'react';
import {useSearchParams} from 'react-router-dom';
import { inchWithDpi } from './utils/measurements';
import Sheet from './utils/sheet';
import { getTheme, getTagRefs } from './utils/tags';
import { ProductUnitProps } from '../screens/ProductUnit';
import { QR } from '../components/QR';
import { TagColors } from '../generated/apiclient';

type TagParams = {
  shortId: string,
  url: string,
  codeSize: number,
  tagSize: number,
  theme: string,
  colors: TagColors,
  rotate?: string,
  outline?: number,
}

const SquareTagSmall = ({shortId, codeSize, tagSize, url, outline, rotate, colors}: TagParams) => {  
  return (
    <div style={{
      width: tagSize+"px",
      height: tagSize+"px",
      display: 'flex', 
      position: 'relative',
      flexDirection: 'column',
      borderRadius: '0px', 
      backgroundColor: colors.background,
      alignItems: 'center', 
      justifyContent: 'center',
      border: outline ? '0.1px solid #000' : undefined,
      rotate: rotate ?? undefined}}>

      <div style={{
        display: 'flex', flexDirection: 'column', textAlign:"center",
        margin: '12px',
      }}>
        <QR 
          qrStyle="square" style={{margin: '0 auto'}} url={url} sizePx={codeSize} 
          overlayText={shortId} colors={colors} />
        <span style={{fontFamily: "Bungee", fontSize:"9px", color: colors.secondary}}>
          LoudTags.com
        </span>
        <span style={{
            fontFamily: "Handlee", fontWeight:"600", fontSize:"9.5px", 
            margin: '-3px -4px',
            color: colors.primary}}>
             Your Voice Message
          </span>
      </div>
    </div>
  );
};

function EssentialStickers({unit, product}:ProductUnitProps) {
  const tags = unit.tags!;
  const nRows = 9;
  const nCols = 7;

  const dpi = 96;
  const inch = inchWithDpi(dpi);
  const baseUrl = window?.location?.origin ?? 'https://loudtags.com';

  const [queryParams] = useSearchParams();
  const sheetRequested = parseInt(queryParams.get('sheet') ?? '-1');

  const sheetIdx = (row: number, col: number): number => {
    if (col < 4) {
      return Math.floor(row/3);
    }
    return 3 + Math.floor(row/4);
  }

  const idxWithin3x4 = (row: number, col: number): number => {
    const columnar = ((unit.colors?.length ?? 1) % 3) == 0;
    if (!columnar) {
      if (col < 4) {
        return col + (row % 3)*4;
      }
      return (6-col)*4 + (row % 4);
    } else {
      if (col < 4) {
        return (2-(row % 3)) + col*3;
      }
      return (col-4)+ ((row % 4)*3);
    }
  }

  const visible = (row: number, col: number) => {
    if (sheetRequested >= 0) {
      return sheetIdx(row, col) == sheetRequested;
    }
    return true;
  }

  const items: Array<Array<React.ReactNode>> = [];
  for (let row = 0; row < nRows; row++) {
    items[row] = []; 
    for (let col = 0; col < nCols; col++) {
      const idx = row*nCols+col;
      const rotate = col < 4 ? '-90deg' : undefined;
      const {shortId, url} = getTagRefs(baseUrl, tags[idx]);
      const {theme, colors} = getTheme(product, unit, idxWithin3x4(row, col));

      items[row][col] = (shortId && visible(row, col) ? <SquareTagSmall 
        shortId={shortId} 
        codeSize={inch(5/8)}
        tagSize={inch(1)}
        url={url}
        rotate={rotate}
        outline={0}
        theme={theme}
        colors={colors} />
        : undefined);
    }
  }

  return (
    <Sheet 
      items={items}
      dpi={dpi}
      widthInch={8.5}
      heightInch={11}
      marginTopInch={1/2}
      marginBottomInch={1/2}
      marginLeftInch={3/8}
      marginRightInch={3/8}
      itemWidthInch={1}
      itemHeightInch={1}
      bleedInch={1/16}
      bumpRightInch={1/32}
      bumpDownInch={0}
      title={``}
      outline={false}
    />
  );
}

export default EssentialStickers;

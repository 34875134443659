import React from 'react';
import './WaveProgress.css'

const Bars = ({animating}) => {
    const n = 30;
    let bars = [];
    const barsPer = 1;
    for (let i = 0; i < n; i++) {
        const v = Math.floor(i / barsPer) / 10;
        bars.push(<div key={i} className={'bars'} style={{
            animation: animating ? `loading 1.6s infinite` : undefined,
            // animation: `loading 1.6s`,
            // animationIterationCount: animating ? 'infinite' : 0,
            backgroundColor: '#FAE075',
            animationDelay: animating ? `${v.toFixed(2)}s` : undefined,
        }}></div>)
    }
    return (<div style={{
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '140px',  
        width: '100%'
    }}>{bars}</div>);
};

export const WaveProgress = ({progress, animating}) => {
    return (
        <div style={{
            height: '100%',  
            width: '100%',
            borderRadius: '8px',
            background: `linear-gradient(90deg, rgba(170,170,170,0.2) 0%, rgba(170,170,170,0.2) ${progress}%, rgba(170,170,170,0) ${progress > 0 ? Math.min(100, progress+2) : 0}%, rgba(170,170,170,0) 100%)`}}>
            <Bars animating={animating}/>
        </div>
    )
}

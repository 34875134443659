import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';

import Tag from "./screens/Tag";
import Landing from "./screens/website/landing";
import Terms from "./screens/website/terms";
import Website from "./screens/website";
import ProductUnit from "./screens/ProductUnit";
import ManageProducts from "./screens/ManageProducts";
import { Authenticated } from "./components/Auth";
import PrivacyPolicy from "./screens/website/privacy";

function App() {
  return (
    <CssBaseline>
      <Router>
        <Routes>
          <Route path="/manage/products" element={<Authenticated><ManageProducts /></Authenticated>} />
          <Route path="/products/:productId/units/:unitId" element={<ProductUnit />} />
          <Route path="/t/:tagId" element={<Tag />} />
          <Route element={<Website />}>
            <Route path="/" element={<Landing />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </Router>
    </CssBaseline>
  );
}

export default App;

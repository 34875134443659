/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductTiming
 */
export interface ProductTiming {
    /**
     * timestamp tag was created
     * @type {Date}
     * @memberof ProductTiming
     */
    created?: Date;
}

/**
 * Check if a given object implements the ProductTiming interface.
 */
export function instanceOfProductTiming(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductTimingFromJSON(json: any): ProductTiming {
    return ProductTimingFromJSONTyped(json, false);
}

export function ProductTimingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductTiming {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
    };
}

export function ProductTimingToJSON(value?: ProductTiming | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
    };
}


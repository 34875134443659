import React, { useState, useEffect } from 'react';

import { useParams } from "react-router-dom";
import { Unit, Product } from "../generated/apiclient";
import { productsApi } from '../api';
import EssentialStickers from '../products/essential-stickers';
import RoundMessageStickers from '../products/round-message-stickers';
import RectangleGiftStickers from '../products/rectangle-gift-tag-stickers';
import HangingGiftTags from '../products/hanging-gift-tags';

export type ProductUnitProps = {
    unit: Unit,
    product: Product,
};

function ProductUnit() {
    let { productId, unitId } = useParams();
    const [unit, setUnit] = useState<Unit | null>(null);
    const [product, setProduct] = useState<Product | null>(null);
    const [err, setErr] = useState<Error | null>(null);
    const [loading, setLoading] = useState<Boolean>(false);

    useEffect(() => {
        const loadUnit = async () => {
            const product = await productsApi.getProduct({productId: productId!});
            const unit = await productsApi.getProductUnit({productId: productId!, unitId: unitId!});
            setProduct(product);
            setUnit(unit);
        };
        setLoading(true);
        loadUnit().catch(setErr).finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <h3>Loading...</h3>
        </div>);
    } else if (err) {
        return (<div>
            <h3>Error Ocurred</h3>
            <p>{err.stack}</p>
        </div>);
    } else if (unit && product) {
        switch (unit.productId) {
            case "essential-stickers":
                return (<EssentialStickers unit={unit} product={product} />)
            case "hanging-gift-tags":
                return (<HangingGiftTags unit={unit} product={product} />)
            case "rectangle-gift-tag-stickers":
                return (<RectangleGiftStickers unit={unit} product={product} />)
            case "round-message-stickers":
                return (<RoundMessageStickers unit={unit} product={product} />)
            default:
                return (<div>
                    <h3>Unknown ProductID</h3>
                    <p>{unit.productId}</p>
                </div>);

        }
    } else {
        return (<div>unexpected state...</div>);
    }
  }
  
  export default ProductUnit;

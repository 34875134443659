import { useState, useRef } from 'react';
import { ControlButton } from './Buttons';
import { FrequencyBars } from './FrequencyBars';


export const WavePlayer = ({src, type}) => {
    const [playing, setPlaying] = useState(false);
    const [streamAudio, setStreamAudio] = useState(null);
    const audioRef = useRef(null);

    const play = () => {
        if (audioRef.current) {
            if (!streamAudio) {
                const audioCtx = new AudioContext({
                    latencyHint: "playback",
                    sampleRate: 44100,
                });
                console.log("connecting audio source...");
                const audioSrc = audioCtx.createMediaElementSource(audioRef.current);
                audioSrc.connect(audioCtx.destination);
                setStreamAudio({ctx: audioCtx, src: audioSrc});
            }

            setPlaying(true);
            audioRef.current.play();
        }
    };

    const stop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            onStop();
        }
    };

    const onStop = () => {
        setPlaying(false);
        //setStreamAudio(null);
    }

    return (
        <div style={{width: '100%'}}>
            <FrequencyBars style={{width: '100%'}} animating={playing} audio={streamAudio} />
            <audio 
                crossOrigin='anonymous'
                ref={audioRef} 
                //onProgress={(p) => { console.log("progress", p); setProgress(p);}}
                onEnded={onStop}
                src={src} type={type} preload="auto" />
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                { !playing ? <ControlButton control='play' onClick={play} /> : null }
                { playing ? <ControlButton control='stop' onClick={stop} /> : null }
            </div>
        </div>
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductThemesInner } from './ProductThemesInner';
import {
    ProductThemesInnerFromJSON,
    ProductThemesInnerFromJSONTyped,
    ProductThemesInnerToJSON,
} from './ProductThemesInner';
import type { ProductTiming } from './ProductTiming';
import {
    ProductTimingFromJSON,
    ProductTimingFromJSONTyped,
    ProductTimingToJSON,
} from './ProductTiming';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * friendly product identifier
     * @type {string}
     * @memberof Product
     */
    productId?: string;
    /**
     * product name
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * description of product
     * @type {string}
     * @memberof Product
     */
    description?: string;
    /**
     * number of tags encoded into this product
     * @type {number}
     * @memberof Product
     */
    tagCount?: number;
    /**
     * width of product output in inches
     * @type {number}
     * @memberof Product
     */
    width?: number;
    /**
     * height of product output in inches
     * @type {number}
     * @memberof Product
     */
    height?: number;
    /**
     * 
     * @type {Array<ProductThemesInner>}
     * @memberof Product
     */
    themes?: Array<ProductThemesInner>;
    /**
     * 
     * @type {ProductTiming}
     * @memberof Product
     */
    timing?: ProductTiming;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tagCount': !exists(json, 'tagCount') ? undefined : json['tagCount'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'themes': !exists(json, 'themes') ? undefined : ((json['themes'] as Array<any>).map(ProductThemesInnerFromJSON)),
        'timing': !exists(json, 'timing') ? undefined : ProductTimingFromJSON(json['timing']),
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'name': value.name,
        'description': value.description,
        'tagCount': value.tagCount,
        'width': value.width,
        'height': value.height,
        'themes': value.themes === undefined ? undefined : ((value.themes as Array<any>).map(ProductThemesInnerToJSON)),
        'timing': ProductTimingToJSON(value.timing),
    };
}


import React, { ReactNode, useEffect, useState } from 'react';
import { authenticatedProductsApi } from '../api';
import { Typography, Button, createTheme, ThemeProvider, Select, MenuItem, SelectChangeEvent, Input } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Product, Unit } from '../generated/apiclient';
import { logout, useAuthUser } from '../components/Auth';
import { Link } from 'react-router-dom';

function ManageProducts() {
    const user = useAuthUser();
    const [products, setProducts] = useState<Array<Product>>([]);
    const [units, setUnits] = useState<Array<Unit>>([]);
    const [selectedProductId, setSelectedProductId] = useState<string>();
    const [newUnitNotes, setNewUnitNotes] = useState<string>('');
    const [newUnitTheme, setNewUnitTheme] = useState<string>('default');

    const [err, setErr] = useState<Error | null>(null);
    const [loading, setLoading] = useState<Boolean>(false);

    const api = authenticatedProductsApi(user!.username, user!.password);

    const loadProducts = async () => {
        setLoading(true);
        try {
            const fetchedProducts = await api.getProducts();
            setProducts(fetchedProducts ?? []);
            if (!selectedProductId && fetchedProducts.length > 0) {
                onProductSelected(fetchedProducts[0]!.productId!);
            }
        } catch(err: any) {
            console.log(err);
            setErr(err);
        } finally {
            setLoading(false);
        }
    }

    const loadUnits = async (productId: string) => {
        setLoading(true);
        try {
            const fetchedUnits = await api.getProductUnits({productId});
            setUnits(fetchedUnits ?? []);
        } catch(err: any) {
            console.log(err);
            setErr(err);
        } finally {
            setLoading(false);
        }
    }

    const createUnit = async (productId: string, notes: string, theme: string) => {
        try {
            const unit: Unit = {
                notes,
                theme,
            };
            await api.postProductUnit({productId, unit});
            await loadUnits(productId)
        } catch(err: any) {
            console.log(err);
            setErr(err);
        } finally {
            setLoading(false);
        }
    }

    const onProductSelected = (productId: string) => {
        console.log(productId);
        setSelectedProductId(productId);
        loadUnits(productId)
    }

    const onProductSelectedEvent = (e: SelectChangeEvent<string>) => {
        onProductSelected(e.target.value);
    }

    const withSelectedProduct = (f: (p: Product|undefined) => ReactNode) => {
        let selected: Product|undefined = undefined;
        if (selectedProductId) {
            for (let p of products) {
                if (p.productId == selectedProductId) {
                    selected = p;
                    break;
                }
            }
        }
        return f(selected);
    }

    useEffect(() => {
        loadProducts();
    }, []);

    const theme = createTheme({
        palette: {
          // mode: 'dark',
          primary: {
            main: '#0D98BA', // ' //lightBlue[900],
            contrastText: '#fff',
          },
          text: {
            primary: '#000'
          }
        },
      });

      const unitsTableCols: GridColDef[] = [
        {
            field: 'timingCreated',
            headerName: 'Created',
            type: 'dateTime',
            sortable: true,
            width: 180,
            valueGetter: (params) => params.row?.timing?.created,
        },
        { field: 'unitId', headerName: 'ID', width: 220 },
        { field: 'notes', headerName: 'Notes', width: 400 },
        { field: 'theme', headerName: 'Theme', width: 100 },
        {
            field: "Render",
            width: 150,
            renderCell: (vals) => {
              return (
                <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    window.open(
                      `/api/products/${vals.row.productId}/units/${vals.row.unitId}.pdf`,
                      '_self'
                    );
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    window.open(
                      `/products/${vals.row.productId}/units/${vals.row.unitId}`,
                      '_self'
                    );
                  }}
                >
                  Web
                </Button>
                </div>
              );
            }
        },
        {
            field: "More",
            renderCell: (vals) => {
              return (
                <Link to={
                    `/api/products/${vals.row.productId}/units/${vals.row.unitId}`
                }>Details</Link>
              );
            }
        }
      ];

    return (
        <ThemeProvider theme={theme}>
            <div style={{
                display: 'flex', 
                justifyContent: 'space-between', 
                padding: '12px',
            }}>

                <Typography variant="h4" component="h2">
                    Manage Products
                </Typography>
                <div>
                {products && selectedProductId ? <Select
                    label="Product"
                    value={selectedProductId}
                    onChange={onProductSelectedEvent}>
                    {products.map(p => (
                        <MenuItem key={p.productId} value={p.productId}>{p.name}</MenuItem>
                    ))}
                </Select> : null }
                <Button onClick={logout}>Logout</Button>
                </div>
            </div>
            <div style={{padding: '12px'}}>
            {
                withSelectedProduct((product) => {
                    return product ?
                    <div>
                        <p>{product.description}</p>
                        <h4>Units</h4>
                        <div style={{display: 'flex', margin: '12px'}}>
                        <Input style={{flex:1}} type="text" onChange={(e) => {
                            setNewUnitNotes(e.target.value);
                        }} />
                        <Select
                            value={newUnitTheme}
                            onChange={(e) => {
                                setNewUnitTheme(e.target.value);
                            }
                        }>
                            {product.themes?.map(t => (
                                <MenuItem key={t.name} value={t.name}>{t.name}</MenuItem>
                            ))}
                        </Select>
                        <Button 
                            variant='contained' 
                            style={{marginLeft: '8px'}}
                            onClick={(e) => {
                                createUnit(product.productId!, newUnitNotes, newUnitTheme);
                            }}>New Unit</Button>
                        </div>
                        <DataGrid
                            rows={units}
                            columns={unitsTableCols}
                            initialState={{
                                pagination: {
                                paginationModel: { page: 0, pageSize: 20 },
                                },
                            }}
                            getRowId={(row) => row.unitId}
                            pageSizeOptions={[20, 100]}
                            />
                    </div>
                    : null;
                })
            }
            </div>
        </ThemeProvider>
    )
  }
  
  export default ManageProducts;

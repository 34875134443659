import React from 'react';

const LoudTagsTermsAndConditions: React.FC = () => {
  return (
    <div style={{padding: '20px'}}>
      <h1>LoudTags LLC - Terms and Conditions</h1>
      <h3>Version 1 - Updated Nov 2023</h3>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using LoudTags, you acknowledge that you have read, understood, and agreed to these Terms. If you do not agree with these Terms, please refrain from using our product.
        </p>
      </section>

      <section>
        <h2>2. Use of LoudTags</h2>
        <p>      
          <h3>2.1. Personal and Non-Commercial Use</h3>
          <p>LoudTags are designed for personal and non-commercial use only. You may not use them for any commercial purpose, including resale.</p>

          <h3>2.2. Age Limitation</h3>
          <p>Users must be at least 18 years old to use LoudTags. If you are under 18, you may only use LoudTags under the supervision of a parent or legal guardian.</p>

          <h3>2.3. Lawful Use</h3>
          <p>LoudTags are intended for lawful purposes only. You agree not to use LoudTags for any illegal, unauthorized, or unethical purposes.</p>
        </p>
      </section>

      <section>
        <h2>3. Voice Messages</h2>
        <p>
          <h3>3.1. User Responsibility</h3>
          <p>Users are solely responsible for the content of the voice messages they record and attach to LoudTags. LoudTags LLC disclaims any liability for the content of these messages.</p>

          <h3>3.2. Content Guidelines</h3>
          <p>Users must not record or share voice messages that contain offensive, harmful, or infringing content, including but not limited to hate speech, copyrighted material, or personal information of others without their consent.</p>

          <h3>3.3. Content Removal</h3>
          <p>LoudTags LLC reserves the right to remove any content that violates these Terms and suspend or terminate access to LoudTags for users who repeatedly violate these guidelines.</p>
        </p>
      </section>

      <section>
        <h2>4. Privacy and Data</h2>
        <p>
          <h3>4.1. Privacy Policy</h3>
          <p>LoudTags LLC is committed to user privacy and data security. We collect and process data in accordance with our Privacy Policy, which you can find on our website at <a href="/privacy">loudtags.com/privacy</a>.</p>
        </p>
      </section>

      <section>
        <h2>5. Intellectual Property</h2>
        <p>
          <h3>5.1. Ownership</h3>
          <p>LoudTags and all related trademarks, logos, and content are the exclusive property of LoudTags LLC. Users do not acquire any rights to these intellectual property assets.</p>

          <h3>5.2. Usage Restrictions</h3>
          <p>Users may not replicate, reproduce, modify, distribute, or create derivative works based on LoudTags without the express written consent of LoudTags LLC.</p>
        </p>
      </section>

      <section>
        <h2>6. Limitation of Liability</h2>
        <p>
          <h3>6.1. Product Disclaimer</h3>
          <p>LoudTags LLC is not responsible for any damages, losses, or injuries resulting from the use of LoudTags. The product is provided on an "as is" basis.</p>

          <h3>6.2. Service Interruptions</h3>
          <p>LoudTags LLC is not liable for any failure or delay in the delivery of voice messages, technical issues, or interruptions in service.</p>
        </p>
      </section>

      <section>
        <h2>7. Data Usage and Third-Party Partnerships</h2>
        <p>
          <h3>7.1. Data Collection and Usage</h3>
          <p>LoudTags LLC reserves the right to collect and retain non-personal data, including but not limited to usage and demographic information, to improve our services and for analytics purposes.</p>

          <h3>7.2. Third-Party Partnerships</h3>
          <p>LoudTags LLC may enter into partnerships or agreements to sell or share non-personal data with third parties for business or commercial purposes. Such data will be anonymized and will not include identifiable user information.</p>
        </p>
      </section>

      <section>
        <h2>8. Data Security</h2>
        <p>
          <h3>8.1. Access to Voice Recordings</h3>
          <p>LoudTags employs a secure mechanism for accessing voice recordings. Each tag is associated with a unique UUID, making it extremely prohibitive to guess or brute force. Physical access to the tag is required to gain access to the recording. That said, anyone with physical access to the tag will be able to access the recording. Never record sensitive information in your voice message.</p>
        </p>
      </section>

      <section>
        <h2>9. Data Retention</h2>
        <p>
          <h3>9.1. Retention Period</h3>
          <p>LoudTags LLC makes our best effort to retain data, including voice recordings, for at least 1 year from the time of recording. After this time, voice recordings may not longer be available for playback.</p>

          <h3>9.2. Extended Retention</h3>
          <p>If you have a LoudTag with a recording that you want to ensure is retained longer than the standard period, please reach out to our support team at <a href="mailto:loudtags@gmail.com?subject=LoudTags Support: Extended Retention">loudtags@gmail.com</a>. We will make efforts to accommodate your request for extended retention.</p>
        </p>
      </section>

      <section>
        <h2>10. Termination</h2>
        <p>LoudTags LLC reserves the right to suspend, terminate, or limit access to LoudTags for any user found in violation of these Terms.</p>
      </section>

      <section>
        <h2>11. Changes to Terms</h2>
        <p>LoudTags LLC may modify these Terms at any time. It is the user's responsibility to review the most current version of the Terms regularly.</p>
      </section>

      <section>
        <h2>12. Contact Information</h2>
        <p>For any questions, concerns, or feedback regarding these Terms or the use of LoudTags, please contact us at <a href="mailto:loudtags@gmail.com?subject=LoudTags Support: Terms">loudtags@gmail.com</a>.</p>
      </section>
    </div>
  );
};

export default LoudTagsTermsAndConditions;

import { Configuration, ProductsApi, TagsApi } from "./generated/apiclient";
import { getApiUrl } from './config';

const configuration = new Configuration({
  basePath: getApiUrl(), 
});

export const productsApi = new ProductsApi(configuration);
export const tagsApi = new TagsApi(configuration);

export const authenticatedProductsApi = (
  username: string, password: string
  ): ProductsApi => {
  return new ProductsApi(
    new Configuration({
      basePath: getApiUrl(), 
      username,
      password,
  })
  )
}
/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagMedia
 */
export interface TagMedia {
    /**
     * media type recorded
     * @type {string}
     * @memberof TagMedia
     */
    type?: TagMediaTypeEnum;
    /**
     * recorded media url (if uploaded)
     * @type {string}
     * @memberof TagMedia
     */
    url?: string;
}


/**
 * @export
 */
export const TagMediaTypeEnum = {
    AudioMpeg: 'audio/mpeg'
} as const;
export type TagMediaTypeEnum = typeof TagMediaTypeEnum[keyof typeof TagMediaTypeEnum];


/**
 * Check if a given object implements the TagMedia interface.
 */
export function instanceOfTagMedia(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TagMediaFromJSON(json: any): TagMedia {
    return TagMediaFromJSONTyped(json, false);
}

export function TagMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagMedia {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function TagMediaToJSON(value?: TagMedia | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'url': value.url,
    };
}


import { Box, Button, Container, Typography } from "@mui/material";
import {  Link } from "react-router-dom";
import ListSection from "../components/ListSection";
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'
import './landing.css'

function Landing() {
  return (
    <div style={{overflow:'hidden'}}>
    <Box 
      sx={{ height: '75vh', maxHeight:'800px', minHeight:'200px',
      backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(./hero.jpg)`,
      backgroundSize: "cover", backgroundPosition:'center center'}}>
      <Box
        sx={{
          flexDirection: "row", p:'14px',
          textAlign:'center', height:'60px'
        }}>
        <Link to="/" style={{fontSize:'48px', color:'#fff', textDecoration:'none', fontFamily:'Bungee' }}>
          LoudTags
        </Link>   
      </Box>
      <Container sx={{mt:'15px'}}>
        <Typography sx={{color:"#fff", fontSize:'30px', textAlign:'center'}}>
          Your Message. Your Voice. 
        </Typography> 
        <Typography sx={{color:"#fff", textAlign:'center', pt:'17vh'}}>
          <Button
            href='https://www.etsy.com/shop/LoudTags'
            variant='outlined' 
            sx={{
              backgroundColor:"#0009",
              '&:hover': {
                backgroundColor: '#0006',
                borderColor:'#fff'
              },
              borderRadius:'1000px', 
              padding: '6px 48px',
              // minWidth:'15vw', 
              // mingHeight:'5vh', 
              fontSize:'25px', 
              color:'#fff', 
              borderColor:'#fff9',
              margin: '4px',
            }}>
            Shop on Etsy
          </Button>
        </Typography> 
      </Container>
    </Box>
    <Box sx={{width: '100%', alignItems: 'center', padding: '20px 0', textAlign: 'center'}}>
      <Typography sx={{color:"#333", fontSize:{xs: '24px', md:'36px'}, paddingTop: '40px', fontWeight: '300'}}>
        Add a voice message to your gift
      </Typography> 
      <Typography sx={{fontFamily: 'Handlee', color:"#333", fontSize:{xs: '28px', md:'40px'},}}>
        for a personalized touch
      </Typography>
      <Box sx={{
        display: 'flex',
        maxWidth: '700px',
        margin: '0 auto',
        justifyContent: 'space-around', flexDirection: 'row', fontSize: '30px', fontWeight: '300', 
        alignItems: 'baseline',
      }}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px',}}>
          <img alt={'Woman'} height="150px" src="./persona-woman-1.png" />
          <span>Scan to<br/>Record</span>
        </div>
        <img alt={'Sending Mail'} src="./send-data.png" height="80px" />
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'}}>
          <img alt={'man'}height="130px" src="./persona-man-1.png" />
          <span>Scan to<br/>Listen</span>
        </div>
      </Box>
    </Box>
  <div style={{width:"100%", textAlign: 'center', marginBottom: '60px'}}>
    <ListSection items={[
      {
        heading: '🎁 Elevate Your Gift-Giving Experience 🌟',
        text: <>
        At LoudTags, we believe gifts are more than just objects; they are messages of love, joy, and connection.
        With LoudTags, we've redefined the art of gift-giving by allowing you to add your unique voice to every gift, creating a memorable and emotional experience.
        </>
      }, {
        heading: '📲 Easy to Use, No Apps Required',
        text: <>
        LoudTags makes personalization easy. 
        Attach one of our stylish tags to your gift. Just scan the QR code on the tag, record your heartfelt message, and watch as the magic unfolds when your recipient scans the tag with their phone.
        It's like giving the gift of your voice along with the present! LoudTags operates seamlessly through your phone's browser, ensuring a hassle-free experience for both you and the recipient.
        </>
      }, {
        heading: '🗣️ Your Message. Your Voice.',
        text: <>
        Express yourself in ways traditional cards or tags cannot match. 
        Whether it's a heartfelt wish, a humorous note, or a sentimental message, LoudTags empowers you to convey your emotions in a truly unique and unforgettable manner.
        </>
      }, {
        heading: '🌈 Make Every Gift Memorable',
        text: <>
        Every LoudTags product is a canvas for your creativity. Whether it's a birthday, anniversary, 
        or any special occasion, our tags allow you to add a personal touch that will be remembered long after the gift is opened. 
        With LoudTags, every present becomes a cherished memory, every message a lasting connection.
        Make every celebration a symphony of personal expressions with LoudTags.
        </>
      }
    ]} />
  </div>
  <Box
      sx={{ 
        height: '500px',
        backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(./hero-birthday.jpg)`,
        backgroundSize: "cover",
        backgroundPosition:'center center',
        textAlign: 'center'
    }}>
  </Box>
  <Typography id="how" variant='h2' style={{fontSize: '36px', marginBottom: '36px', textAlign:'center', marginTop:'40px'}}>
    How it Works  
  </Typography>
  <Container sx={{display:'flex', flexWrap:'no-wrap', justifyContent:'center'}}>
    

    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '15px', marginTop:'100px', width:'300px'}}>
      <div className='phone-demo-people'>
      <img height='100%' alt={'man'}  src="./persona-woman-1.png" />
      </div>
      <span style={{fontSize:"18px", marginTop:'15px', fontFamily:'quicksand', textAlign:'center'}}> Charlie records a voice message on her gift tag. She can’t wait for Quinn to hear it!</span>
    </div>
    <div >
      <div id="phone-container" style={{marginTop:'50px', }}>
        <div style={{margin:'-210px -100px -180px'}}>
          <DeviceFrameset device="iPhone X" zoom={.5} >
            <div>
              <img height="100%" width='100%' src="./RecordMessage.gif" />
            </div>
          </DeviceFrameset>
        </div> 
    </div>
    </div>
  </Container>
  
  <Container sx={{display:'flex', flexWrap:'no-wrap', justifyContent:'center', alignContent:'center',}}>
    <div id="phone-container" style={{marginTop:'50px'}}>
        <div style={{margin:'-210px -100px -180px'}}>
          <DeviceFrameset device="iPhone X" zoom={.5} >
            <div>
              <img width="100%" height='100%' src="./ScanMessage.gif"/>
            </div>
          </DeviceFrameset>
        </div> 
      </div>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '15px', marginTop:'100px', width:'300px'}}>
      <div className='phone-demo-people'>
        <img height='100%' alt={'man'}  src="./persona-man-1.png" />
      </div>          
      <span style={{fontSize:'18px', marginTop:'15px', fontFamily:'quicksand', textAlign:'center'}}> Quinn sees the LoudTag on his gift from Charlie. He scans it and is delighted to hear her voice message!</span>
    </div>
  </Container>
  <Box
      sx={{ 
        height: '500px',
        backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(./gifts.jpg)`,
        backgroundSize: "cover",
        backgroundPosition:'center center',
        textAlign: 'center'
    }}>
  </Box>
  <Box sx={{width: '100%', alignItems: 'center', padding: '30px 0', textAlign: 'center'}}>
    <ListSection id="faq" title="Frequently Asked Questions" items={[
      {
        heading: '1. How do I record a voice message on a LoudTags tag?',
        text:
          "It's easy! Open your phone's camera, scan the QR code on the LoudTag, and it will direct you to our website. From there, you can record your personalized voice message directly in your browser. No app download required!",
      },
      {
        heading: '2. Do I need to download an app to use LoudTags?',
        text:
          'No need to clutter your phone with additional apps! LoudTags operates through our website, allowing you to scan, record, and play messages directly from your browser window. Quick, convenient, and hassle-free!',
      },
      {
        heading: '3. Can I play back the recorded message before attaching it to the gift?',
        text:
          'Absolutely! After recording your message, you can preview it on the website before finalizing. This ensures your message sounds just right and adds that perfect personal touch to your gift.',
      },
      {
        heading: '4. Can I use LoudTags with any smartphone?',
        text:
          'You can use LoudTags with most any modern smartphone! LoudTags is compatible with most any smartphone equipped with a camera, microphone and a QR code scanning feature. Whether you are team iPhone or team Android, spreading joy with personalized messages is just a scan away.',
      },
      {
        heading: '5. How long can my voice message be?',
        text:
          'Each voice message can be up to 60 seconds long, providing you with ample time to express your warm wishes or holiday cheer.',
      },
      {
        heading: '6. Are the tags reusable?',
        text:
          'While the tags are not designed for multiple recordings, they make fantastic keepsakes! Your recipients can keep and cherish the tags as a memorable part of your thoughtful gift.',
      },
      {
        heading: '7. Can I edit or re-record my message after scanning the tag?',
        text:
          'Once a message is recorded and saved, it cannot be edited. However, you can listen to your recording before you save and record a new message if you want to change or update your greeting.',
      },
      {
        heading: '8. Can I save the same recorded voice message to multiple LoudTags?',
        text:
          'Yes! When you scan a new unused LoudTag, you will have an option to "Copy from a Recent LoudTag". This option allows you to copy the message you recorded for a previous LoudTag and save it to the new LoudTag too. Please note, this option is only shown if you\'ve recorded and saved at least one LoudTag on your device within the last few days.',
      },
      {
        heading: '9. What if I encounter issues with the LoudTags website?',
        text: <>
          We are here to help! Reach out to us at <a href="mailto:loudtags@gmail.com?subject=LoudTags Support: Question">loudtags@gmail.com</a>, and we'll do our best to resolve any issues.
        </>,
      },
    ]} />
  </Box>
  </div>
  );
}

export default Landing;
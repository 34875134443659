import {
    Button, Typography, Box, createTheme, ThemeProvider
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import Recorder from '../components/Recorder';
import { WavePlayer } from '../components/WavePlayer';

import { useParams } from "react-router-dom";
import { Tag } from "../generated/apiclient";
import { tagsApi } from '../api';
import { WaveProgress } from '../components/WaveProgress';


const bluegreen = {
    background: '#0D98BA', // '#19647E',
    text: {
      logo: '#FFF',
      message: '#FFF',
      id: '#FFF'
    }
  };
const colors = bluegreen;

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: '#e84600',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff'
    }
  },
});

interface RenderParams {
    tagId?: string;
    tag?: Tag;
    onTagUpdate?: (tag: Tag) => void
}

interface ConfirmationParams {
    onDismiss?: () => void
}

const RecordSection = ({tagId, onTagUpdate}: RenderParams) => {
    const [mediaErr, setMediaErr] = useState<Error | null>(null);

    return !mediaErr
    ? <React.Fragment>
        <Typography variant="body1" color="textPrimary" sx={{textAlign:'center', fontFamily: "Quicksand", mt: '20px'}}>
            <b>Record</b> your message. 
            <br/>
            Once you <b>Save</b>, your message will
            <br/>
            <b>Play</b> for anyone who scans this tag.
        </Typography>
        <Recorder onSave={onTagUpdate} onMediaErr={setMediaErr} tagId={tagId!}></Recorder>
    </React.Fragment>
    : <React.Fragment>
        <img src="/hear-no-evil.png" width="48px" height="48px" />
        <Typography variant="h5" color="textPrimary" sx={{textAlign:'center', fontFamily: "Quicksand", mt: '20px'}}>
            <b>On no</b>, we can't hear you!
        </Typography>
        <Typography variant="body1" color="textPrimary" sx={{textAlign:'center', fontFamily: "Quicksand", mt: '24px'}}>
            You can <b>Try Again</b> and remember to<br/>
            <b>Allow Loudtags</b> to access your microphone when asked.
        </Typography>
        <Typography variant="body1" color="textPrimary" sx={{
            textAlign:'center', fontFamily: "Quicksand", m: '24px 0', lineHeight: '30px'
        }}>
            If you are not asked for your permission, <br/>
            check <b>your browser's URL bar</b> for one of these icons
        </Typography>
        <div style={{flexDirection: 'row', alignContent: 'center'}}>
            <img src="/mic.png" width="40px" height="40px" />
            <img src="/lock.png" width="40px" height="40px" />
            <img src="/info.png" width="40px" height="40px" />
        </div>
        <Typography variant="body1" color="textPrimary" sx={{
            textAlign:'center', fontFamily: "Quicksand", mt: '20px', lineHeight: '24px'
        }}>
            <b>Click</b> on it, and you should see an option to
            <br/>
            <b>Allow Loudtags</b> to access your microphone
        </Typography>
        <Button 
            sx={{color: '#fff', marginTop: '20px', width: '100%'}} 
            variant='contained'
            onClick={() => setMediaErr(null)}>Try Again</Button>
    </React.Fragment>;
};

const PlaySection = ({tag}: RenderParams) => {
    return (
        <React.Fragment>
            <Typography variant="body1" color="textPrimary" sx={{textAlign:'center', fontFamily: "Quicksand", mt: '20px'}}>
                Press <b>Play</b>
                <br/>
                for a voice a message
                <br/>
                sent just for you!
            </Typography>
            <WavePlayer src={tag!.media!.url!} type={tag!.media!.type!} />
        </React.Fragment>
    );
};

const Confirmation = ({onDismiss}: ConfirmationParams) => {
    return (
        <React.Fragment>
            <Typography variant="body1" color="textPrimary" sx={{textAlign:'center', fontSize: '20px', fontFamily: "Quicksand", margin: '20px 0'}}>
                You're <b>done</b> recording!
            </Typography>
            <img src="/thumbsup.png" width="120px" height="120px" />
            <Typography variant="body1" color="textPrimary" sx={{textAlign:'center', fontSize: '16px', fontFamily: "Quicksand", margin: '20px 0'}}>
                This <b>LoudTag</b> will now 
                <br/>
                <b>Play</b> your message any time it's scanned.
            </Typography>
            <Button 
                sx={{color: '#fff', marginTop: '20px', width: '100%'}} 
                variant='contained'
                onClick={onDismiss}>Play Preview</Button>
        </React.Fragment>
    );
};

function TagScreen() {
    let { tagId } = useParams();
    const [tag, setTag] = useState<Tag | null>(null);
    const [err, setErr] = useState<Error | null>(null);
    const [loading, setLoading] = useState<Boolean>(false);
    const [confirmation, setConfirmation] = useState<Boolean>(false);

    useEffect(() => {
        const loadTag = async () => {
            const tag = await tagsApi.getTag({tagId: tagId!});
            setTag(tag);
            console.log("got tag", tag);
        };
        setLoading(true);
        loadTag().catch(setErr).finally(() => setLoading(false));
    }, []);

    let content: React.ReactNode = null;

    if (loading || (!tag && !err)) {
        content = (
            <WaveProgress progress={0} animating={true} />
        );
    } else if (err) {
        content = (
            <div style={{color: '#fff', textAlign: 'center'}}>
                <img src="/grimace.png" width="60px" height="60px" />
                <h3 style={{marginBottom: 0}}>Uh oh, something went wrong!</h3>
                <h5 style={{marginTop: 0, marginBottom: '24px'}}>We'll work fixing this, please try again later.</h5>
                <code>{err?.message}</code>
            </div>
        );
    } else if (confirmation) {
        content = <Confirmation onDismiss={() => setConfirmation(false)} />
    } else if (tag?.status == 'created') {
        content = (
            <RecordSection tagId={tagId} onTagUpdate={(updatedTag) => {
                setConfirmation(true);
                setTag(updatedTag);
            }} />
        );
    } else if (tag?.status == 'saved') {
        content = (
            <PlaySection tagId={tagId} tag={tag!} />
        );
    } else {
        content = (
            <div style={{color: '#fff', textAlign: 'center'}}>
                <img src="/grimace.png" width="60px" height="60px" />
                <h3 style={{marginBottom: 0}}>Uh oh, something went wrong!</h3>
                <h5 style={{marginTop: 0, marginBottom: '24px'}}>We'll work fixing this, please try again later.</h5>
                <code>Unexpected status: {tag?.status}</code>
            </div>
        );
    }

    return (
    <ThemeProvider theme={theme}>
    <Box sx={{ 
        display: 'flex', flexDirection: 'column', justifyContent: 'start',
         alignItems: 'center', backgroundColor: grey[900], minHeight: '100vh'}}>
        <Box sx={{
            display: 'flex', width:"100%", 
            flex: 1, flexDirection: 'column', 
            justifyContent: 'start', alignItems: 'center', 
            minWidth:320, maxWidth: 600, padding: '12px', 
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
            background: colors.background }}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: 'flex'}}>
                <Typography 
                    variant="h4" 
                    color="textPrimary" 
                    sx={{fontFamily: 'Bungee', textAlign:'center', m: '10px'}}>
                LoudTags.com
                </Typography>
                </div>
                <Typography 
                    variant="h6" 
                    color="textPrimary" 
                    sx={{fontFamily: 'Quicksand', fontWeight: '700', fontSize: '18px', textAlign:'center', marginTop: '-14px', marginBottom: '18px'}}>
                Your message. Your voice.
                </Typography>
            </div>
            {content}
        </Box>
    </Box>
    </ThemeProvider>
    );
  }
  
  export default TagScreen;
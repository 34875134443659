/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductTiming } from './ProductTiming';
import {
    ProductTimingFromJSON,
    ProductTimingFromJSONTyped,
    ProductTimingToJSON,
} from './ProductTiming';
import type { TagColors } from './TagColors';
import {
    TagColorsFromJSON,
    TagColorsFromJSONTyped,
    TagColorsToJSON,
} from './TagColors';

/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     * unit identifier
     * @type {string}
     * @memberof Unit
     */
    unitId?: string;
    /**
     * product identifier
     * @type {string}
     * @memberof Unit
     */
    productId?: string;
    /**
     * notes about this unit
     * @type {string}
     * @memberof Unit
     */
    notes?: string;
    /**
     * array of tag ids
     * @type {Array<string>}
     * @memberof Unit
     */
    tags?: Array<string>;
    /**
     * unit theme name
     * @type {string}
     * @memberof Unit
     */
    theme?: string;
    /**
     * the friendly message displayed in the product design
     * @type {string}
     * @memberof Unit
     */
    message?: string;
    /**
     * 
     * @type {Array<TagColors>}
     * @memberof Unit
     */
    colors?: Array<TagColors>;
    /**
     * 
     * @type {ProductTiming}
     * @memberof Unit
     */
    timing?: ProductTiming;
}

/**
 * Check if a given object implements the Unit interface.
 */
export function instanceOfUnit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UnitFromJSON(json: any): Unit {
    return UnitFromJSONTyped(json, false);
}

export function UnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Unit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'colors': !exists(json, 'colors') ? undefined : ((json['colors'] as Array<any>).map(TagColorsFromJSON)),
        'timing': !exists(json, 'timing') ? undefined : ProductTimingFromJSON(json['timing']),
    };
}

export function UnitToJSON(value?: Unit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unitId': value.unitId,
        'productId': value.productId,
        'notes': value.notes,
        'tags': value.tags,
        'theme': value.theme,
        'message': value.message,
        'colors': value.colors === undefined ? undefined : ((value.colors as Array<any>).map(TagColorsToJSON)),
        'timing': ProductTimingToJSON(value.timing),
    };
}


import React from 'react';
import {
  Dialog, DialogTitle, ListItem, ListItemButton, List, ListItemText, ListItemIcon
} from '@mui/material';
import CopyIcon from "@mui/icons-material/CopyAll";
import moment from 'moment';

export const HistoryDialog = ({onSelect, handleClose, open, history = []}) => {
  return (
  <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{color: '#000'}}>Recent LoudTags</DialogTitle>
      <List sx={{ pt: 0, color: '#000' }}>
      {history.map((tag) => (
          <ListItem disableGutters key={tag.tagId}>
          <ListItemButton onClick={() => onSelect(tag)}>
              <ListItemIcon>
                  <CopyIcon  />
              </ListItemIcon>
              <ListItemText 
                  secondary={`Recorded ${moment(tag.timing.saved).fromNow()}`} 
                  primary={<span>LoudTag: <code>{tag.tagId.slice(0, 5)}</code></span>} />
          </ListItemButton>
          </ListItem>
      ))}
      </List>
  </Dialog>
  );
}

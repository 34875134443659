import React from 'react';
import {useSearchParams} from 'react-router-dom';
import { inchWithDpi } from './utils/measurements';
import Sheet from './utils/sheet';
import { ProductUnitProps } from '../screens/ProductUnit';
import { getTheme, getTagRefs } from './utils/tags';
import { CircularText } from '../components/CircularText';
import { QR } from '../components/QR';
import { TagColors } from '../generated/apiclient';

type TagParams = {
  shortId: string,
  url: string,
  codeSize: number,
  tagSize: number,
  theme: string,
  colors: TagColors,
  rotate?: string,
  outline?: number,
}

const RoundTagLarge = ({shortId, codeSize, tagSize, url, outline, rotate, colors}: TagParams) => {
  return (
    <div style={{
      width: tagSize+"px",
      height: tagSize+"px",
      display: 'flex', 
      position: 'relative',
      flexDirection: 'column',
      borderRadius: '1000px', 
      //backgroundColor: colors.background,
      alignItems: 'center', 
      justifyContent: 'center',
      rotate: rotate ?? undefined}}>
      {
        outline ?
        <div style={{
          position: 'absolute', borderRadius: '1000px', 
          width: `${outline}px`, height: `${outline}px`, border: '0.1px solid #000'
        }}></div>
        : null
      }
      <CircularText
        style={{
          position: "absolute",
          zIndex: 2,
        }} 
        topDy={38}
        size={tagSize-8}
        topText="Enjoy a Message"
        topFont={{
          family: 'Handlee',
          size: 54,
          //variant: 'small-caps',
          spacing: 1.5,
          weight: "300",
          color: colors.secondary ?? colors.primary,
        }} 
        bottomText="Just for You"
        bottomFont={{
          family: 'Handlee',
          size: 54,
          //variant: 'small-caps',
          spacing: 1.5,
          weight: "300",
          color: colors.secondary ?? colors.primary,
        }} />

      <div style={{
        position: "absolute", display: 'flex', flexDirection: 'column', textAlign:"center",
        borderRadius: '6px', 
        backgroundColor: colors.background ?? '#fff', 
        padding: '2px',
      }}>
        <QR qrStyle="square" style={{margin: '0 auto'}} url={url} sizePx={codeSize} 
          overlayText={shortId} colors={colors} />
        <span style={{fontFamily: "Bungee", fontSize:"9px", color: colors.secondary}}>
          LoudTags.com
        </span>
        <span style={{
          fontFamily: "Handlee", marginTop: '-3px', fontWeight:"600", fontSize:"9px",
          color: colors.primary}}>
          Scan for Your Voice Message
        </span>
      </div>
    </div>
  );
};

function RoundMessageStickers({unit, product}:ProductUnitProps) {
    const tags = unit.tags!;
    const nRows = 5;
    const nCols = 4;

    const dpi = 96;
    const inch = inchWithDpi(dpi);
    const baseUrl = window?.location?.origin ?? 'https://loudtags.com';

    const [queryParams] = useSearchParams();
    const sheetRequested = parseInt(queryParams.get('sheet') ?? '-1');

    const bumpRightInch = parseFloat(queryParams.get('bumpRight') ?? '0');
    const bumpDownInch = parseFloat(queryParams.get('bumpDown') ?? '0');

    const sheetIdx = (row: number, col: number): number =>
      Math.floor(col / 2);

    const visible = (row: number, col: number): boolean =>
      sheetRequested < 0 || sheetIdx(row, col) == sheetRequested;

    const items: Array<Array<React.ReactNode>> = [];
    for (let row = 0; row < nRows; row++) {
      items[row] = []; 
      for (let col = 0; col < nCols; col++) {
        const idx = row*nCols+col;
        const {shortId, url} = getTagRefs(baseUrl, tags[idx]);
        const {theme, colors} = getTheme(product, unit, idx);

        items[row][col] = (shortId && visible(row, col) ? <RoundTagLarge 
          shortId={shortId} 
          codeSize={inch(5/8)}
          tagSize={inch(2+1/16)}
          //outline={inch(2)}
          url={url}
          theme={theme}
          colors={colors} />
          : undefined);
      }
    }

    return (
      <Sheet 
        items={items}
        dpi={dpi}
        widthInch={8.5}
        heightInch={11}
        marginTopInch={3/8}
        marginBottomInch={3/8}
        marginLeftInch={5/32}
        marginRightInch={5/32}
        itemWidthInch={2}
        itemHeightInch={2}
        bleedInch={1/32}
        bumpRightInch={bumpRightInch}
        bumpDownInch={bumpDownInch}
        title={``}
        outline={false}
      />
    );
  }
  
  export default RoundMessageStickers;
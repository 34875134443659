/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TagMedia } from './TagMedia';
import {
    TagMediaFromJSON,
    TagMediaFromJSONTyped,
    TagMediaToJSON,
} from './TagMedia';
import type { TagTiming } from './TagTiming';
import {
    TagTimingFromJSON,
    TagTimingFromJSONTyped,
    TagTimingToJSON,
} from './TagTiming';

/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * tag identifier
     * @type {string}
     * @memberof Tag
     */
    tagId?: string;
    /**
     * unit identifier
     * @type {string}
     * @memberof Tag
     */
    unitId?: string;
    /**
     * number of plays/views
     * @type {number}
     * @memberof Tag
     */
    plays?: number;
    /**
     * status of tag
     * @type {string}
     * @memberof Tag
     */
    status?: TagStatusEnum;
    /**
     * 
     * @type {TagTiming}
     * @memberof Tag
     */
    timing?: TagTiming;
    /**
     * 
     * @type {TagMedia}
     * @memberof Tag
     */
    media?: TagMedia;
}


/**
 * @export
 */
export const TagStatusEnum = {
    Created: 'created',
    Saved: 'saved'
} as const;
export type TagStatusEnum = typeof TagStatusEnum[keyof typeof TagStatusEnum];


/**
 * Check if a given object implements the Tag interface.
 */
export function instanceOfTag(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tagId': !exists(json, 'tagId') ? undefined : json['tagId'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'plays': !exists(json, 'plays') ? undefined : json['plays'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'timing': !exists(json, 'timing') ? undefined : TagTimingFromJSON(json['timing']),
        'media': !exists(json, 'media') ? undefined : TagMediaFromJSON(json['media']),
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tagId': value.tagId,
        'unitId': value.unitId,
        'plays': value.plays,
        'status': value.status,
        'timing': TagTimingToJSON(value.timing),
        'media': TagMediaToJSON(value.media),
    };
}


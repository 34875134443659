import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";

type QRColors = {
  primary: string,
  secondary?: string,
  background?: string,
}

export type QRStyle = 'dot' | 'rounded' | 'square';

type QRParams = {
  url: string,
  sizePx: number,
  colors: QRColors,
  style?: React.CSSProperties,
  qrStyle?: QRStyle,
  overlayText?: string,
}

const DEFAULT_QR_COLORS: QRColors = {
  primary: '#000',
};

export const QR = ({url, sizePx, style, overlayText, qrStyle = 'square', colors = DEFAULT_QR_COLORS}: QRParams) => {
  const [dataUrl, setDataUrl] = useState<string>('');
  const qrSize = Math.max(300, sizePx);

  const qrCode = new QRCodeStyling({
    width: qrSize,
    height: qrSize,
    data: url,
    dotsOptions: {
      color: colors.primary,
      type:  qrStyle == 'dot' ? "dots" : qrStyle == 'rounded' ? "extra-rounded" :"square"
    },
    backgroundOptions: {
      color: colors.background ?? '#fff0',
    },
    cornersSquareOptions: {
      "type": qrStyle == 'dot' ? "dot" : qrStyle == 'rounded' ? "extra-rounded" :"square",
      "color": colors.secondary ?? colors.primary
    },
    cornersDotOptions: {
      "type": qrStyle == 'square' ? "square" : "dot",
      "color": colors.secondary ?? colors.primary
    },
    qrOptions: {
      errorCorrectionLevel: 'Q',
    }
  });

  useEffect(() => {
    qrCode.getRawData('png')
    .then(blob => {
      if (blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        })
      }
    })
    .then((txt) => setDataUrl(txt as string));
  }, [url, qrStyle]);
  
  return (
    <div style={{
      width: sizePx+"px",
      height: sizePx+"px",
      display: 'flex', 
      flexDirection: 'column',
      backgroundColor: '#fff0',
      alignItems: 'center', 
      justifyContent: 'center',
      position: "relative",
      ...style}}>
      {/* Logo image overlay */}
      <svg style={{zIndex: 3, display: 'absolute'}} width={0.32*sizePx} height={0.32*sizePx} viewBox="0 0 192 192" xmlSpace="preserve">
        <g transform="matrix(1.722297131 0 0 1.722297131 96 96)" id="3SQMPq2rLDE4kNzWDCQbS"  >
          <path style={{fill: colors.background ?? '#fff', opacity: 1}} transform=" translate(0, 0)" d="M 0 -55.73951 C 30.76821 -55.73951 55.73951 -30.768210000000003 55.73951 0 C 55.73951 30.76821 30.768210000000003 55.73951 0 55.73951 C -30.76821 55.73951 -55.73951 30.768210000000003 -55.73951 0 C -55.73951 -30.76821 -30.768210000000003 -55.73951 0 -55.73951 z" />
        </g>
        <g transform="matrix(1.5401791105 -0.6857319205 0.6216945576 1.3963488385 86.0991249065 101.1073266913)" id="upY38wmiS1c2E1NImDGr7"  >
          <g>
            <g transform="matrix(0.94269452 0 0 0.938905091 14.2515035263 0)" id="RiQeHPkqkAb2bLuUvbyyN"  >
              <path style={{fill: colors.secondary ?? colors.primary, opacity: 1}} transform=" translate(0, 0.0000032028)" d="M -17.30941 -25.5922 C -17.16144 -27.18985 -16.47377 -28.68387 -15.3657 -29.81506 L 7.492329999999999 -49.09274 L 7.492329999999999 -49.09274 C 9.28746 -50.60669 11.771289999999999 -50.92167 13.870859999999999 -49.9016 C 15.970429999999999 -48.881530000000005 17.30941 -46.70926 17.30941 -44.32315 L 17.30941 43.80897 L 17.30941 43.80897 C 17.30941 46.396240000000006 15.856259999999999 48.75121 13.57865 49.855000000000004 C 11.30104 50.958800000000004 8.60798 50.613200000000006 6.66461 48.96775 L -13.99203 31.477700000000002 L -13.99203 31.477700000000002 C -15.98544 29.784290000000002 -17.14775 27.271750000000004 -17.16701 24.61437 z" />
            </g>
            <g transform="matrix(0.9426945201 0 0 0.938905091 -17.202089164 0)" id="sBFgAR-3GIDY8U-8yGO2L"  >
              <path style={{fill: colors.secondary ?? colors.primary, opacity: 1}} transform=" translate(-0.000005, 0)" d="M 10.51223 -28.55269 C 12.53758 -28.55269 14.17946 -26.86584 14.17946 -24.784999999999997 L 14.17946 25.091410000000003 L 14.17946 25.091410000000003 C 14.17946 27.003020000000003 12.67111 28.552690000000002 10.81047 28.552690000000002 L -3.918429999999999 28.552690000000002 L -3.918429999999999 28.552690000000002 C -9.585439999999998 28.552690000000002 -14.17945 23.83282 -14.17945 18.010580000000004 L -14.17945 -17.756869999999992 L -14.17945 -17.756869999999992 C -14.17945 -23.719229999999992 -9.474879999999999 -28.55267999999999 -3.6715 -28.55267999999999 z" />
            </g>
          </g>
        </g>
      </svg>

      {/* QR code as image from data URL */}
      {dataUrl ? <img style={{position: "absolute", zIndex: 2}} width={sizePx} height={sizePx} src={dataUrl} /> : null }
      {overlayText
        ? <span style={{
          position: "absolute", zIndex: 3, right: 0, bottom: 0,
          backgroundColor: colors.background ?? '#fff',
          // height:`${sizePx / 8}px`,
          borderRadius: '2px 0 0 0',
          fontFamily: 'PT Mono',
          fontSize: '5px',
          paddingLeft: '1px',
          display: 'flex',
          alignItems: 'end',
          }}>
            {overlayText}
          </span>
        : null }
    </div>
  );
}

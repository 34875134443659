/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TagColors } from './TagColors';
import {
    TagColorsFromJSON,
    TagColorsFromJSONTyped,
    TagColorsToJSON,
} from './TagColors';

/**
 * 
 * @export
 * @interface ProductThemesInner
 */
export interface ProductThemesInner {
    /**
     * theme name
     * @type {string}
     * @memberof ProductThemesInner
     */
    name?: string;
    /**
     * default friendly message to render for this theme
     * @type {string}
     * @memberof ProductThemesInner
     */
    message?: string;
    /**
     * 
     * @type {Array<TagColors>}
     * @memberof ProductThemesInner
     */
    colors?: Array<TagColors>;
}

/**
 * Check if a given object implements the ProductThemesInner interface.
 */
export function instanceOfProductThemesInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductThemesInnerFromJSON(json: any): ProductThemesInner {
    return ProductThemesInnerFromJSONTyped(json, false);
}

export function ProductThemesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductThemesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'colors': !exists(json, 'colors') ? undefined : ((json['colors'] as Array<any>).map(TagColorsFromJSON)),
    };
}

export function ProductThemesInnerToJSON(value?: ProductThemesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'message': value.message,
        'colors': value.colors === undefined ? undefined : ((value.colors as Array<any>).map(TagColorsToJSON)),
    };
}


import React from 'react';
import {px, inchWithDpi} from './measurements';

type SheetProps = {
  dpi?: number,
  widthInch: number,
  heightInch: number,
  marginTopInch: number,
  marginBottomInch: number,
  marginLeftInch: number,
  marginRightInch: number,
  itemWidthInch: number,
  itemHeightInch: number,
  items: Array<Array<React.ReactNode>>,
  itemSpacingHorizontalInch?: number,
  itemSpacingVerticalInch?: number,
  bleedInch?: number,
  bumpRightInch?: number,
  bumpDownInch?: number,
  title?: string,
  outline?: boolean,
  cutMarkers?: boolean
};

const Sheet = ({
  dpi = 96,
  widthInch,
  heightInch,
  marginTopInch,
  marginBottomInch,
  marginLeftInch,
  marginRightInch,
  itemWidthInch,
  itemHeightInch,
  items,
  itemSpacingHorizontalInch = 0,
  itemSpacingVerticalInch = 0,
  bleedInch = 0,
  bumpRightInch = 0,
  bumpDownInch = 0,
  title = '',
  outline = false,
  cutMarkers = false,
}:SheetProps) => {
    const inch = inchWithDpi(dpi);

    // adjust for printer-specific alignment oddities
    const bumpRight = inch(bumpRightInch);
    const bumpDown = inch(bumpDownInch);
    const bleed = inch(bleedInch);
    const itemWidth = inch(itemWidthInch) + 2*bleed;
    const itemHeight = inch(itemHeightInch) + 2*bleed;

    return (
      <div>
          <div style={{
            position: 'absolute', 
            textAlign: 'center', 
            fontSize: '0.8rem', 
            fontFamily: 'monospace, monospace', 
            top: px(inch(1/8)), 
            left: 0, 
            right: 0
            }}>
              <span>{title}</span>
          </div>
          <div style={{
              width: px(inch(widthInch)), 
              height: px(inch(heightInch)),
              paddingTop: px(inch(marginTopInch) - bleed + bumpDown),
              paddingBottom: px(inch(marginBottomInch) - bleed - bumpDown),
              paddingLeft: px(inch(marginLeftInch) - bleed + bumpRight),
              paddingRight: px(inch(marginRightInch) - bleed - bumpRight),
          }}>
              <div style={{
                  width: '100%', 
                  height: '100%', 
                  outline: outline ? '1px solid red': undefined,
              }}>
                  {
                  items.map((row, rowIdx) => (
                  <div key={`row-${rowIdx}`} style={{
                    display: 'flex', flexDirection: 'row',
                    marginTop: itemSpacingVerticalInch && rowIdx > 0 ? px(inch(itemSpacingVerticalInch)) : undefined,
                    position: 'relative',
                  }}>
                      {
                        cutMarkers
                        ? <React.Fragment>
                          <div style={{
                            position: 'absolute',
                            borderTop: '1px solid black',
                            borderBottom: '1px solid black',
                            height: '100%',
                            width: px(inch(marginLeftInch) - 3),
                            left: `-${px(inch(marginLeftInch))}`,
                          }}>
                          </div>
                          <div style={{
                            position: 'absolute',
                            borderTop: '1px solid black',
                            borderBottom: '1px solid black',
                            height: '100%',
                            width: px(inch(marginRightInch) - 3),
                            right: `-${px(inch(marginRightInch))}`,
                          }}>
                          </div>
                        </React.Fragment> 
                        : null
                      }
                      {
                      row.map((el, colIdx)  => (
                      <div key={`col-${colIdx}`} style={
                        {
                          display: 'flex',
                          flexDirection: 'row',
                          marginLeft: itemSpacingHorizontalInch && colIdx > 0 ? px(inch(itemSpacingHorizontalInch)) : undefined,
                          width: px(itemWidth), 
                          height: px(itemHeight),
                          outline: outline ? '0.1px solid red': undefined,
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative'
                        }
                      }>
                        {
                          cutMarkers && rowIdx == 0
                          ? <React.Fragment>
                          <div style={{
                              position: 'absolute',
                              borderLeft: '1px solid black',
                              borderRight: '1px solid black',
                              height: px(inch(marginTopInch) - 3),
                              top: `-${px(inch(marginTopInch))}`,
                              width: '100%',
                              left: 0
                            }}>
                          </div>
                          </React.Fragment> 
                          : null
                        }
                        {
                          cutMarkers && rowIdx == (items.length-1)
                          ? <React.Fragment>
                          <div style={{
                              position: 'absolute',
                              borderLeft: '1px solid black',
                              borderRight: '1px solid black',
                              height: px(inch(marginBottomInch) - 3),
                              bottom: `-${px(inch(marginBottomInch))}`,
                              width: '100%',
                              left: 0
                            }}>
                          </div>
                          </React.Fragment> 
                          : null
                        }
                        {el}
                      </div>))
                      }
                  </div>
                  ))
                  }
              </div>
          </div>
      </div>);
  };

export default Sheet;

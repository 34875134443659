import { FormControl, InputLabel, Input, FormHelperText, Button } from '@mui/material';
import React, {useContext, useEffect, useState} from 'react'

type User = {
  username: string,
  password: string,
};

type AuthProviderParams = {
  children: JSX.Element,
  value: User|null,
};

type AuthenticatedParams = {
  children: JSX.Element
};

const AuthContext = React.createContext<User|null>(null);

export function AuthProvider({children, value}: AuthProviderParams) {
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthUser(){
  return useContext(AuthContext);
}

export function Authenticated({children}: AuthenticatedParams) {
  const [user, setUser] = useState<User|null>(null);

  const onCredentials = (e: any) => {
    e.preventDefault();
    const username = e.target.elements[0].value;
    const password = e.target.elements[1].value;

    const user: User = {
      username,
      password
    };
    console.log(`saving user: ${username}`);
    const userStr = btoa(`${username}:${password}`);
    localStorage.setItem("manage-user", userStr);
    setUser(user);
  } 

  useEffect(() => {
    const userStr = localStorage.getItem("manage-user");
    if (userStr) {
      const [username, password] = atob(userStr).split(':');
      const user: User = {
        username, 
        password
      }
      console.log(`loading user: ${username}`);
      setUser(user);
    }
  }, []);

  return (
    <div>
      {
        user 
        ? (<AuthProvider value={user}>{children}</AuthProvider>)
        : <div style={{width: '100%', textAlign: 'center'}}>
          <div style={{ maxWidth: '400px', margin: '40px auto'}}>
            <form style={{ display: 'flex', flexDirection: 'column'}} onSubmit={onCredentials}>
              <FormControl>
                <InputLabel htmlFor="username-input">Username</InputLabel>
                <Input id="username-input" />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="password-input">Password</InputLabel>
                <Input id="password-input" type="password" />
              </FormControl>
              <Button type="submit">Submit</Button>
            </form>
          </div>
        </div>
      }
    </div>
  );
}

export function logout(){
  localStorage.setItem("manage-user", "");
  window.location.reload();
}
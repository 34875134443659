import React from 'react';

const LoudTagsPrivacyPolicy: React.FC = () => {
  return (
    <div style={{padding: '20px'}}>
      <h1>LoudTags LLC - Privacy Policy</h1>
      <h3>Version 1 - Updated Nov 2023</h3>

      <section>
        <h2>1. Information Collection</h2>
        <p>
          <h3>1.1. Personal Information</h3>
          <p>We may collect personal information, such as names and email addresses, when users voluntarily provide such information, for example, when contacting us or registering an account.</p>

          <h3>1.2. Voice Messages</h3>
          <p>When users record and attach voice messages to LoudTags, the content is stored to facilitate delivery to devices that scan the tag to play. Data is not retained by LoudTags LLC beyond what is necessary for the intended use.</p>

          <h3>1.3. Usage and Demographic Information</h3>
          <p>We may collect and retain basic usage and demographic information, including but not limited to user device information and location, to improve our services and user experience.</p>
        </p>
      </section>

      <section>
        <h2>2. Information Use</h2>
        <p>
          <h3>2.1. Service Delivery</h3>
          <p>We use the information collected to deliver voice messages and facilitate the use of LoudTags.</p>

          <h3>2.2. Communication</h3>
          <p>We may use contact information to respond to inquiries, provide updates, and send important information related to LoudTags.</p>

          <h3>2.3. Analytics</h3>
          <p>Automatically collected information, including usage and demographic data, may be used for analytics purposes to improve our services and understand user behavior.</p>
        </p>
      </section>

      <section>
        <h2>3. Information Sharing</h2>
        <p>
          <h3>3.1. Third-Party Service Providers</h3>
          <p>We may share information with third-party service providers to facilitate service delivery, analytics, and communication. These providers are obligated to protect the information in compliance with this Privacy Policy.</p>

          <h3>3.2. Legal Compliance</h3>
          <p>We may disclose information if required by law or in response to valid legal requests, such as court orders or subpoenas.</p>

          <h3>3.3. Business Transfers</h3>
          <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, user information may be transferred to the acquiring entity.</p>
        </p>
      </section>

      <section>
        <h2>4. Data Security</h2>
        <p>
          <p>We implement reasonable security measures to protect user information. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>
        </p>
      </section>

      <section>
        <h2>5. User Choices</h2>
        <p>
          <h3>5.1. Opt-Out</h3>
          <p>Users may opt-out of receiving non-essential communications from LoudTags LLC by emailing support at <a href="mailto:loudtags@gmail.com?subject=LoudTags Support: Communication Opt Out">loudtags@gmail.com</a>. However, certain communications related to service updates and important information are necessary and cannot be opted out of.</p>
        </p>
      </section>

      <section>
        <h2>6. Changes to Privacy Policy</h2>
        <p>
          <p>We reserve the right to update this Privacy Policy at any time. Users will be notified of any material changes when possible, and it is their responsibility to review the updated policy.</p>
        </p>
      </section>

      <section>
        <h2>7. Contact Information</h2>
        <p>
          <p>For any questions, concerns, or requests regarding this Privacy Policy or the use of LoudTags, please contact us at <a href="mailto:loudtags@gmail.com?subject=LoudTags Support: Privacy">loudtags@gmail.com</a>.</p>
        </p>
      </section>
    </div>
  );
};

export default LoudTagsPrivacyPolicy;
type TextFont = {
  family: string,
  size: number,
  color: string,
  variant?: string,
  weight?: string,
  spacing?: number,
};

type CircularTextParams = {
  size: number,
  topText: string,
  bottomText: string,
  topFont?: TextFont,
  bottomFont?: TextFont,
  style?: React.CSSProperties,
  topDy?: number,
};

const DEFAULT_FONT = {
  family: '',
  variant: '',
  weight: '400',
  size: 42,
  color: '#000',
}

export const CircularText = ({size, topText, bottomText, topDy, topFont = DEFAULT_FONT, bottomFont = DEFAULT_FONT, style}: CircularTextParams) => {
  return (
    <svg style={style} width={size} height={size} viewBox="0 0 500 500">
      <path fill="#aaa0" id="upcurve" d="M50,250A200,200 0 1 1450,250" />
      <path fill="#aaa0" id="downcurve" d="M 50 250 a 200 200 0 1 0 400 0" />
      <text dy={topDy ?? topFont.size*0.8} fontSize={topFont.size} fill={topFont.color} width="500">
        <textPath 
          fontFamily={topFont.family} 
          fontVariant={topFont.variant} 
          letterSpacing={topFont.spacing} 
          fontWeight={topFont.weight} 
          textAnchor="middle" startOffset="50%" xlinkHref="#upcurve">
          {topText}
        </textPath>
      </text>
      <text fontSize={bottomFont.size} fill={bottomFont.color} width="500">
        <textPath
          fontFamily={bottomFont.family} 
          fontVariant={bottomFont.variant} 
          letterSpacing={bottomFont.spacing} 
          fontWeight={bottomFont.weight} 
          alignmentBaseline="baseline" textAnchor="middle" startOffset="50%" xlinkHref="#downcurve">
          {bottomText}
        </textPath>
      </text>
    </svg>
  );
}

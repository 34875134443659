export const API_URL_OVERRIDE: string|null =
  null;
  // 'http://localhost:5000';

export const getApiUrl = (): string => {
  let currentUrl = API_URL_OVERRIDE ?? window.location.origin;
  // react dev server client should use the local emulator endpoint for api
  return currentUrl != 'http://localhost:3000' ? currentUrl : 'http://localhost:5000';
}

export const DEMO_TAG_MODE = false;

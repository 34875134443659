import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        height:'100%',
        padding:'25px', 
        color:'text.secondary', 
        mt:'50px'
      }}>
        <Grid container  rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{color:'text.secondary'}}>
          <Grid item xs={12} sm={12} md={3}>
            <Typography
              sx={{
                textAlign:{xs: "center", md: 'center'},
                fontFamily: "Bungee",  
              }}
              gutterBottom
              variant="h4"
            >
              LoudTags
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
          <Typography  sx={{fontSize:'16px', lineHeight:'28px',  textAlign:{xs: "center"}}}>
              <Link color="inherit" style={{ textDecoration: "none"}} href="mailto:loudtags@gmail.com?subject=LoudTags Support: Contact">
                Contact Us
              </Link>
            </Typography>   
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography  sx={{fontSize:'16px', lineHeight:'28px', textAlign:{xs: "center"}}}>
              <Link color="inherit" style={{ textDecoration: "none"}} href="/terms">
                Terms and Conditions
              </Link>
            </Typography>     
          </Grid> 
          <Grid item xs={12} sm={12} md={3}>
            <Typography  sx={{fontSize:'16px', lineHeight:'28px', textAlign:{xs: "center"}}}>
              <Link color="inherit" style={{ textDecoration: "none"}} href="/privacy">
                Privacy Policy
              </Link>
            </Typography>     
          </Grid> 
          <Grid item xs={12} md={6}>
            <Typography  sx={{textAlign:{xs: "center"}}}>
            Copyright LoudTags LLC {new Date().getFullYear()}.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography  sx={{textAlign:{xs: "center"}}}>
            <Link style={{textDecoration:'none'}} color="inherit" href="https://www.flaticon.com/free-icons/send-data">
              Send Data icon by iconixar / Flaticon.
            </Link>
            <Link style={{textDecoration:'none'}} color="inherit" href="https://www.freepik.com/free-vector/multiracial-people-avatars_7085153.htm">
              &nbsp;Persona images by pikisuperstar /  Freepik.
            </Link>
            </Typography>
          </Grid>
        </Grid>
    </Box>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Tag,
} from '../models';
import {
    TagFromJSON,
    TagToJSON,
} from '../models';

export interface GetTagRequest {
    tagId: string;
}

export interface PutMediaRequest {
    tagId: string;
    body: Blob;
}

/**
 * 
 */
export class TagsApi extends runtime.BaseAPI {

    /**
     * Get tag
     * Get Tag
     */
    async getTagRaw(requestParameters: GetTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling getTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tags/{tagId}`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * Get tag
     * Get Tag
     */
    async getTag(requestParameters: GetTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tag> {
        const response = await this.getTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Upload media and save tag
     */
    async putMediaRaw(requestParameters: PutMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling putMedia.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling putMedia.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'audio/mpeg';

        const response = await this.request({
            path: `/api/tags/{tagId}/save`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * 
     * Upload media and save tag
     */
    async putMedia(requestParameters: PutMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tag> {
        const response = await this.putMediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

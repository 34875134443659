import { Button } from '@mui/material';
import {FiberManualRecord, Stop, PlayArrow, Delete} from '@mui/icons-material';

const buttonIconColor = '#fff' //  '#0D98BA';

const controlStyles = {
    'play': {
        icon: <PlayArrow htmlColor={buttonIconColor} fontSize='large'/>,
        color: 'primary', 
        variant: 'contained',
        label: 'Play'
    },
    'stop': {
        icon: <Stop htmlColor={buttonIconColor} fontSize='large'/>,
        color: 'primary', 
        variant: 'contained',
        label: 'Stop'
    },
    'record': {
        icon: <FiberManualRecord htmlColor={buttonIconColor} fontSize='large'/>,
        color: 'primary', 
        variant: 'contained',
        label: 'Start Recording'
    },
    'clear': {
        icon: <Delete fontSize='large'/>,
        color: 'error', 
        variant: 'outlined',
        label: 'Clear'
    }
};

export const ControlButton = ({onClick, disabled, control}) => {
    const s = controlStyles[control] || {};

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '20px', marginLeft: '8px'}}>
            <Button
                sx={{borderRadius: '6px', height: '64px', width: '64px'}} 
                color={s.color} 
                variant={s.variant} 
                disabled={disabled}
                onClick={onClick}>
                    {s.icon}
            </Button>
            <span style={{
                fontFamily: 'Quicksand', 
                marginTop: '4px',
                fontSize: '16px',
                color: disabled ? '#bdbdbd' : '#fff'}}>{s.label}</span>
        </div>
    );
}
import React, { useState, useEffect, useRef } from 'react';
import { Mp3MediaRecorder } from 'mp3-mediarecorder';
import moment from 'moment';
import mp3RecorderWorker from 'workerize-loader!./RecorderWorker'; // eslint-disable-line import/no-webpack-loader-syntax
import {
    Button
} from '@mui/material';
import { ControlButton } from './Buttons';
import { WavePlayer } from './WavePlayer';
import { tagsApi } from '../api';
import { FrequencyBars } from './FrequencyBars';
import { HistoryDialog } from './HistoryDialog';

const MAX_DURATION_MS = 60000;
const UPDATES_PER_SECOND = 10;
const TAG_HISTORY = "loudtags_history";
const MAX_HISTORY_COUNT = 10;
const RECENT_HISTORY_HOURS = 3 * 24;

const Recorder = ({tagId, onSave, onMediaErr}) => {
    const recorderRef = useRef(null);
    const worker = useRef(null);
    const [blob, setBlob] = useState(null);
    const [recorderState, setRecorderState] = useState('inactive');
    const [progress, setProgress] = useState(0);
    const [ticker, setTicker] = useState(null);
    const [audio, setAudio] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const [blobSetFromHistory, setBlobSetFromHistory] = useState(false);

    const getTagHistory = () => {
        const item = localStorage.getItem(TAG_HISTORY);
        const h = item ? JSON.parse(item) : [];
        // order by most recent first
        return h.sort(((a, b) => moment(b.timing.saved).diff(a.timing.saved)))
    }

    const getRecentTagHistory = () => {
        const oldestRecent = moment().subtract({hours: RECENT_HISTORY_HOURS});
        return getTagHistory()
            .filter(i => moment(i.timing.saved).isAfter(oldestRecent));
    }

    const addToHistory = (tagId, saveTime) => {
        let h = getTagHistory();
        h.push({
            tagId,
            timing: {
                saved: saveTime
            }
        });

        // order by most recent first
        h = h.sort(((a, b) => moment(b.timing.saved).diff(a.timing.saved)))
        if (h.length > MAX_HISTORY_COUNT) {
            h = h.slice(0, MAX_HISTORY_COUNT);
        }
        localStorage.setItem(TAG_HISTORY, JSON.stringify(h));
    }

    const history = getRecentTagHistory();

    const save = async () => {
        const tag = await tagsApi.putMedia({tagId: tagId, body: blob});
        if (tag?.tagId && !blobSetFromHistory) {
            addToHistory(tag.tagId, tag.timing.saved);
        }
        if (onSave) {
            onSave(tag);
        }
    }

    useEffect(() => {
        worker.current = mp3RecorderWorker();
    }, []);

    const startRecording = () => {
        setRecorderState('initializing');
        window.navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            const recorder = new Mp3MediaRecorder(stream, { worker: worker.current });
            recorderRef.current = recorder;

            const audioCtx = new AudioContext({
                latencyHint: "interactive",
                sampleRate: 44100,
            });
            const audioSrc = audioCtx.createMediaStreamSource(stream);
            setAudio({ctx: audioCtx, src: audioSrc});
            
            if (ticker) {
                clearInterval(ticker);
            }
            const start = Date.now();
            let t = null;
            t = setInterval(() => {
                const p = 100.0 * (Date.now() - start) / MAX_DURATION_MS;
                if (p >= 100 && progress < 100) {
                    clearInterval(t);
                    console.log('stopping at max duration');
                    stopRecording();
                } else {
                    setProgress(p);
                }
            }, 1000 / UPDATES_PER_SECOND);
            setTicker(t);

            recorder.ondataavailable = (event) => {
                console.log('ondataavailable', event.data);
                stream.getTracks().forEach(function(track) {
                    track.stop();
                  });
                setBlob((prev) => event.data);
            };
            recorder.onstart = () => {
                console.log('onstart');
                setRecorderState('recording');
            };
            recorder.onstop = () => {
                console.log('onstop');
                setRecorderState('inactive');
            };
            recorder.start();
        }).catch(err => {
            setRecorderState('inactive');
            onMediaErr(err);
        });
    };

    const stopRecording = () => {
        setProgress(100);
        if (ticker) {
            clearInterval(ticker);
            setTicker(null);
        }
        recorderRef.current.stop();
    };

    const clearRecording = () => {
        setProgress(0);
        setRecorderState('inactive');
        setBlob(null);
        setBlobSetFromHistory(false);
    };

    const copyFromHistory = async (item) => {
        const tag = await tagsApi.getTag({tagId: item.tagId});
        // download blob
        const result = await fetch(tag.media.url);
        // const blob = new Blob([result.value], { type: tag.media.type });
        // // set blob as though we just recorded it
        setBlob(await result.blob());
        setBlobSetFromHistory(true);
    }

    return (
        <React.Fragment>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{margin: "12px 0"}}>
                { blob 
                ? <WavePlayer src={URL.createObjectURL(blob)} type={blob.type} /> 
                : <div>
                    <FrequencyBars animating={recorderState == 'recording' } audio={audio} />
                    <div style={{width: '100%'}}>
                        <div style={{
                            width: `${progress}%`, 
                            backgroundColor: '#FAE075', 
                            borderRadius: '3px', 
                            height: '3px',
                            marginTop: '3px',
                            marginBottom: '-6px',
                        }}></div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        { recorderState == 'recording' 
                        ? <ControlButton control='stop' onClick={stopRecording}   /> 
                        : <ControlButton control='record' onClick={startRecording} /> }
                    </div>
                </div>}
            </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '20px'}}>
                <Button sx={{display: 'flex', flex: 1, width: "100%"}} 
                        disabled={!blob} 
                        variant='contained'
                        onClick={save}>
                    Save
                </Button>
                { !!blob
                    ? <Button 
                        sx={{display: 'flex', color: '#fff', flex: 1, marginTop: '20px', width: '100%'}} 
                        variant='text'
                        onClick={clearRecording}>
                        Start Over
                    </Button>
                    : null
                }
                
                {
                    history.length > 0 && recorderState == 'inactive' && !blob
                    ? (
                        <Button sx={{display: 'flex', color: '#fff', flex: 1, marginTop: '20px', width: '100%'}} 
                            variant='text'
                            onClick={() => { setShowHistory(true); }}>
                            Copy from Recent LoudTag
                        </Button>
                    ) : null
                }
            </div>
            <HistoryDialog
                open={showHistory && recorderState == 'inactive'} 
                handleClose={() => { setShowHistory(false); }} 
                onSelect={(t) => {
                    setShowHistory(false);
                    copyFromHistory(t);
                }}
                history={history}
            />
        </React.Fragment>
    )
}

export default Recorder;
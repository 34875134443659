import { DEMO_TAG_MODE } from "../../config";
import { TagColors, Unit, Product } from "../../generated/apiclient";

export const DEFAULT_TAG_COLORS: TagColors = {
  primary: '#fff',
  secondary: '#0D98BA',
  background: '#fff',
};

export type ThemeColors = {
  theme: string,
  colors: TagColors,
  message?: string, 
}

export type TagRefs = {
  shortId?: string,
  url: string,
}

export const getTagRefs = (baseUrl: string, tagId?: string): TagRefs => {
  if (DEMO_TAG_MODE) {
    return {
      shortId: 'demo',
      url: 'https://loudtags.com?src=demoTag'
    }
  }
  return {
    shortId: tagId?.slice(0, 5),
    url: `${baseUrl}/t/${tagId}`
  }
}

export const getTheme = (product: Product, unit: Unit, idx: number): ThemeColors => {
  const showAllThemesForDemoMode = false;
  if (!DEMO_TAG_MODE || !showAllThemesForDemoMode) {
    const colors = unit.colors ?? [DEFAULT_TAG_COLORS];
    return {
      theme: unit.theme ?? 'default',
      message: unit.message,
      colors: colors[idx % colors.length],
    }
  }

  // DEMO MODE
  // We use all themes/colors from the Product
  const allThemeColors: Array<ThemeColors> = [];
  for (let t of product.themes!) {
    let themeColors = t.colors ?? [DEFAULT_TAG_COLORS];

    // demo mode is weird... but I promise this makes sense when you print.
    // you want 2 of the single-color themes in a row
    if (themeColors.length == 1) {
      themeColors.push(themeColors[0]);
    }
    for (let c of themeColors) {
      allThemeColors.push({
        theme: t.name!,
        colors: c
      })
    }
  }
  return allThemeColors[idx % allThemeColors.length];
}
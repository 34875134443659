/* tslint:disable */
/* eslint-disable */
/**
 * LoudTags
 * LoudTags API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagColors
 */
export interface TagColors {
    /**
     * html color code for primary color
     * @type {string}
     * @memberof TagColors
     */
    primary: string;
    /**
     * html color code for secondary color
     * @type {string}
     * @memberof TagColors
     */
    secondary?: string;
    /**
     * html color code for background color
     * @type {string}
     * @memberof TagColors
     */
    background?: string;
}

/**
 * Check if a given object implements the TagColors interface.
 */
export function instanceOfTagColors(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "primary" in value;

    return isInstance;
}

export function TagColorsFromJSON(json: any): TagColors {
    return TagColorsFromJSONTyped(json, false);
}

export function TagColorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagColors {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primary': json['primary'],
        'secondary': !exists(json, 'secondary') ? undefined : json['secondary'],
        'background': !exists(json, 'background') ? undefined : json['background'],
    };
}

export function TagColorsToJSON(value?: TagColors | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary': value.primary,
        'secondary': value.secondary,
        'background': value.background,
    };
}

